import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import CleanHTML from '../cleanHTML';

const sizing = {
  height: '400px',
  maxWidth: '400px',
  minWidth: '316px',
};

const copyImgWrap = {
  borderRadius: '32px',
  ...sizing,
};

const copyImg = {
  objectPosition: 'center left',
  ...sizing,
};

/**
 * Retention page ForecastEngagement component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <ForecastEngagement />
 */

const ForecastEngagement = () => {
  const {
    contentfulMemberExperience: {
      forecastEngagementContent: {
        childMarkdownRemark: { html },
      },
      forecastEngagementImage: { fixed },
      forecastEngagementTitle,
    },
  } = useStaticQuery(graphql`
    query cahpsForecastEngagement {
      contentfulMemberExperience {
        forecastEngagementTitle
        forecastEngagementContent {
          childMarkdownRemark {
            html
          }
        }
        forecastEngagementImage {
          fixed(width: 700, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    }
  `);
  const hasContentUl = html.includes('<ul>');

  return (
    <section
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.mainWrapper
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexWrapReverse,
          base.itemsCenter,
          base.justifyBetween,
          base.w100,
          main.dataItem,
          main.mb60
        )}
      >
        <Img
          fixed={fixed}
          alt=""
          style={copyImgWrap}
          imgStyle={copyImg}
          className={cx(base.mb4)}
        />
        <div className={cx(base.ma2, base.w100, main.imgCopyWrap)}>
          <p className={cx(base.mb4, base.mt0, main.subHeading)}>
            {forecastEngagementTitle}
          </p>
          <div
            className={(main.statementsContent, main.stCopy)}
            data-shift={`${hasContentUl}`}
          >
            <CleanHTML html={html} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForecastEngagement;
