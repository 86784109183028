import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import CleanHTML from '../cleanHTML';
import PDFAttachment from '../pdf-attachment';

const copyImg = {
  maxHeight: '360px',
  maxWidth: '360px',
  minHeight: '316px',
  minWidth: '316px',
};

/**
 * Retention page CAHPSControl component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <CAHPSControl />
 */

const CAHPSControl = () => {
  const {
    contentfulMemberExperience: {
      controlCahpsAttachment,
      controlCAHPSContent: {
        childMarkdownRemark: { html },
      },
      controlCAHPSImage: { fluid },
      controlCAHPSTitle,
    },
  } = useStaticQuery(graphql`
    query cahpsControl {
      contentfulMemberExperience {
        controlCAHPSTitle
        controlCAHPSContent {
          childMarkdownRemark {
            html
          }
        }
        controlCAHPSImage {
          fluid(maxWidth: 450, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        controlCahpsAttachment {
          file {
            contentType
            url
          }
          title
        }
      }
    }
  `);
  const attFile = controlCahpsAttachment.title.replace(/\s/g, '_');
  const attExt = controlCahpsAttachment.file.contentType.replace(
    'application/',
    ''
  );

  return (
    <section
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.mainWrapper
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexWrapReverse,
          base.itemsCenter,
          base.justifyBetween,
          base.w100,
          main.dataItem,
          main.mb50
        )}
      >
        <div className={cx(base.ma2, base.w100, main.imgCopyWrap)}>
          <p className={cx(base.mb2, base.mt0, main.subHeading)}>
            {controlCAHPSTitle}
          </p>
          <div className={(main.statementsContent, main.stCopy)}>
            <CleanHTML html={html} />
            <PDFAttachment
              url={controlCahpsAttachment.file.url}
              filename={`${attFile}.${attExt}`}
              link="Learn More"
              border
            />
          </div>
        </div>
        <Img
          fluid={fluid}
          alt=""
          style={copyImg}
          imgStyle={copyImg}
          className={cx(base.mb4)}
        />
      </div>
    </section>
  );
};

export default CAHPSControl;
