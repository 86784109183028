import React from 'react';
import cx from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import CleanHTML from '../cleanHTML';

/**
 * Retention page ProfilePopulation component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <ProfilePopulation />
 */

const ProfilePopulation = () => {
  const {
    contentfulMemberExperience: {
      profilePopulationContent: {
        childMarkdownRemark: { html },
      },
      profilePopulationImage: {
        file: { url },
      },
      profilePopulationTitle,
    },
  } = useStaticQuery(graphql`
    query cahpsProfilePopulation {
      contentfulMemberExperience {
        profilePopulationTitle
        profilePopulationContent {
          childMarkdownRemark {
            html
          }
        }
        profilePopulationImage {
          file {
            url
          }
        }
      }
    }
  `);
  const hasContentUl = html.includes('<ul>');

  return (
    <section
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.mainWrapper,
        main.popNeeds
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexWrap,
          base.itemsCenter,
          base.justifyBetween,
          base.w100,
          main.dataItemLg,
          main.mb60
        )}
      >
        <div
          className={cx(
            base.flex,
            base.h100,
            base.itemsCenter,
            base.justifyCenter,
            base.mb4,
            base.relative,
            base.w100,
            main.popNeedsImgWrap
          )}
        >
          <img
            src={url}
            alt=""
            className={cx(
              base.absolute,
              base.bottom0,
              base.top0,
              main.popNeedsImg
            )}
          />
        </div>
        <div className={cx(base.ma2, base.w100, main.imgCopyWrap)}>
          <p className={cx(base.mb4, base.mt0, main.subHeading)}>
            {profilePopulationTitle}
          </p>
          <div
            className={(main.statementsContent, main.stCopy)}
            data-shift={`${hasContentUl}`}
          >
            <CleanHTML html={html} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfilePopulation;
