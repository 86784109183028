import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import CleanHTML from '../cleanHTML';
import PDFAttachment from '../pdf-attachment';

const imgWrap = {
  maxWidth: '650px',
};

/**
 * Retention page EngageProvider component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <EngageProvider />
 */

const EngageProvider = () => {
  const {
    contentfulMemberExperience: {
      engageProviderAttachment,
      engageProviderContent,
      engageProviderImage: { fluid },
      engageProviderSubContent,
      engageProviderTitle,
    },
  } = useStaticQuery(graphql`
    query cahpsHosEngageProvider {
      contentfulMemberExperience {
        engageProviderTitle
        engageProviderContent {
          childMarkdownRemark {
            html
          }
        }
        engageProviderImage {
          fluid(maxWidth: 800, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        engageProviderSubContent {
          childMarkdownRemark {
            html
          }
        }
        engageProviderAttachment {
          file {
            contentType
            url
          }
          title
        }
      }
    }
  `);
  const attFile = engageProviderAttachment.title.replace(/\s/g, '_');
  const attExt = engageProviderAttachment.file.contentType.replace(
    'application/',
    ''
  );

  return (
    <section
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.overflowHidden,
        base.relative,
        base.w100,
        main.mainWrapper,
        main.engageProvider
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsStart,
          base.justifyBetween,
          base.w100,
          main.dataItemXl,
          main.mb60
        )}
      >
        <div className={cx(base.ma3, main.mb30, base.w100)}>
          <p className={cx(base.mb0, base.mt0, main.heading)}>
            {engageProviderTitle}
          </p>
          <div className={(main.statementsContent, main.stCopy, main.stCopyLg)}>
            <CleanHTML html={engageProviderContent.childMarkdownRemark.html} />
          </div>
        </div>
        <div
          className={cx(
            base.flex,
            base.itemsStart,
            base.justifyBetween,
            base.w100,
            main.engageProviderContentWrap
          )}
        >
          <Img
            fluid={fluid}
            alt=""
            style={imgWrap}
            className={cx(main.engageProviderImg)}
          />
          <div
            className={(main.statementsContent, main.stCopy)}
            data-block="true"
          >
            <CleanHTML
              html={engageProviderSubContent.childMarkdownRemark.html}
            />
            <PDFAttachment
              url={engageProviderAttachment.file.url}
              filename={`${attFile}.${attExt}`}
              link="Learn More"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EngageProvider;
