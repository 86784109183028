import React, { useEffect, useRef, useState } from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import { useStaticQuery, graphql } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import CleanHTML from '../cleanHTML';
import useDimensions from '../util/useDimension';

import PrevArrow from '../../images/prev-arrow.svg';
import NextArrow from '../../images/next-arrow.svg';

/**
 * Retention page ComplementaryEngagement component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <ComplementaryEngagement />
 */

const ComplementaryEngagement = () => {
  const slider = useRef();
  const {
    contentfulMemberExperience: {
      complementaryEngagementContent,
      complementaryEngagementImages,
      complementaryEngagementTitle,
    },
    contentfulAsset: {
      file: { url },
    },
  } = useStaticQuery(graphql`
    query cahpsComplementaryEngagement {
      contentfulMemberExperience {
        complementaryEngagementTitle
        complementaryEngagementContent {
          childMarkdownRemark {
            html
          }
        }
        complementaryEngagementImages {
          fluid(maxWidth: 1000, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      contentfulAsset(file: { fileName: { regex: "/activate-outreach-bg/" } }) {
        file {
          url
        }
      }
    }
  `);
  const [container, dimensions] = useDimensions({ resize: true });
  const [imgSize, setImgSize] = useState(900);
  const [slideIndex, setSlideIndex] = useState(0);
  const [showDots, setShowDots] = useState(false);

  useEffect(() => {
    if (dimensions) {
      const isMobileWidth = dimensions.width < 995;

      if (isMobileWidth) {
        setImgSize(dimensions.width - 60);
        setShowDots(true);
      } else {
        setShowDots(false);
      }
    }
  }, [dimensions]);

  useEffect(() => {
    if (slider.current) {
      slider.current.slickGoTo(slideIndex);
    }
  }, [slideIndex, slider]);

  const settings = {
    arrows: false,
    dots: showDots,
    infinite: false,
    initialSlide: 2,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
  };

  const nextSlide = () => {
    if (slideIndex === complementaryEngagementImages.length - 1) {
      setSlideIndex(0);
    } else {
      setSlideIndex(slideIndex + 1);
    }
  };

  const prevSlide = () => {
    if (slideIndex === 0) {
      setSlideIndex(complementaryEngagementImages.length - 1);
    } else {
      setSlideIndex(slideIndex - 1);
    }
  };

  return (
    <section
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.overflowHidden,
        base.relative,
        base.w100,
        main.mainWrapper
      )}
    >
      <img
        src={url}
        alt=""
        className={cx(
          base.absolute,
          base.bottom0,
          base.h100,
          base.top0,
          main.outreachImgBg
        )}
      />
      <div
        ref={container}
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsStart,
          base.justifyBetween,
          base.w100,
          main.dataItem,
          main.mb60
        )}
        style={{ padding: '0' }}
      >
        <div
          className={cx(base.ma3, main.mb30, base.w100)}
          style={{ padding: '0 50px' }}
        >
          <p className={cx(base.mb0, base.mt0, main.heading)}>
            {complementaryEngagementTitle}
          </p>
          <div className={(main.statementsContent, main.stCopy, main.stCopyLg)}>
            <CleanHTML
              html={complementaryEngagementContent.childMarkdownRemark.html}
            />
          </div>
        </div>
        <div
          className={cx(
            base.flex,
            base.itemsCenter,
            base.justifyEnd,
            base.w100,
            main.complementaryEngagementSliderBtnWrap
          )}
        >
          <button
            type="button"
            className={cx(
              base.flex,
              base.itemsCenter,
              base.justifyCenter,
              main.complementaryEngagementSliderBtn
            )}
            onClick={prevSlide}
          >
            <span className={base.srOnly}>Previous</span>
            <img src={PrevArrow} alt="" aria-hidden="true" />
          </button>
          <button
            type="button"
            className={cx(
              base.flex,
              base.itemsCenter,
              base.justifyCenter,
              main.complementaryEngagementSliderBtn
            )}
            onClick={nextSlide}
          >
            <span className={base.srOnly}>Next</span>
            <img src={NextArrow} alt="" aria-hidden="true" />
          </button>
        </div>
        <div
          id="member-experience-slider"
          className={cx(
            base.flex,
            base.itemsCenter,
            base.justifyEnd,
            base.w100
          )}
        >
          <Slider
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...settings}
            ref={slider}
            className={cx(main.complementaryEngagementSlider)}
          >
            {complementaryEngagementImages.map(({ fluid }) => (
              <div className={main.slideImg}>
                <Img
                  fluid={fluid}
                  alt=""
                  style={{
                    maxWidth: `${imgSize}px`,
                  }}
                  className={cx(base.w100)}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default ComplementaryEngagement;
