import { useState, useCallback, useEffect } from 'react';

/**
 * Get bounding client of ref React element.
 * @function
 *
 * @param   {boolean} resize watch for window resize and update dimension properties?
 * @returns {Array}          [ref element, bounding client properties, element node]
 */
const useDimensions = ({ resize = false } = {}) => {
  const [dimensions, setDimensions] = useState({});
  const [node, setNode] = useState();
  const ref = useCallback(element => {
    setNode(element);
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (node) {
      // update UI thread
      const measure = () =>
        window.requestAnimationFrame(() => {
          const rect = node.getBoundingClientRect();
          setDimensions(rect);
        });

      measure();

      if (resize) {
        if ('ResizeObserver' in window) {
          const resizeObserver = new ResizeObserver(measure);

          resizeObserver.observe(node);

          return () => resizeObserver.unobserve(node);
        }

        window.addEventListener(
          'resize',
          () => {
            let throttled = false;
            // only run if we're not throttled
            if (!throttled) {
              // actual callback action
              measure();
              // we're throttled!
              throttled = true;
              // set a timeout to un-throttle
              setTimeout(() => {
                throttled = false;
              }, 250);
            }
          },
          false
        );

        return () => {
          window.removeEventListener('resize', measure);
        };
      }

      return () => {};
    }
  }, [node]);

  return [ref, dimensions, node];
};

export default useDimensions;
