import React from 'react';
import Img from 'gatsby-image';
import cx from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import CleanHTML from '../cleanHTML';

import FiveStars from '../../images/fiveStar.svg';

const achieverImg = {
  maxWidth: '190px',
  minWidth: '190px',
};

/**
 * Retention page Achievers component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Achievers />
 */

const Achievers = () => {
  const {
    contentfulMemberExperience: { cahpsAchieversTitle, cahpsAchievers },
  } = useStaticQuery(graphql`
    query cahpsAchievers {
      contentfulMemberExperience {
        cahpsAchieversTitle
        cahpsAchievers {
          fluid(maxWidth: 250, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.hosHeroCardWrap
      )}
    >
      <h2
        className={cx(
          base.mb3,
          main.heading,
          main.headingSm,
          main.heroTitleTeal
        )}
      >
        <CleanHTML html={cahpsAchieversTitle} />
      </h2>
      <ul
        className={cx(
          base.flex,
          base.flexWrap,
          base.itemsCenter,
          base.justifyCenter,
          base.ma0,
          base.pa0,
          base.w100,
          main.hosHeroCardList
        )}
      >
        {cahpsAchievers.map(({ fluid }) => (
          <li
            className={cx(
              base.itemsCenter,
              base.justifyCenter,
              base.ma4,
              base.pa0,
              base.w100,
              main.hosHeroCard
            )}
          >
            <Img
              fluid={fluid}
              alt=""
              style={achieverImg}
              imgStyle={achieverImg}
              className={cx(base.ma0)}
            />
            <img
              src={FiveStars}
              alt=""
              aria-hidden="true"
              className={cx(base.w100)}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Achievers;
