import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';

import PDFIcon from '../images/pdf-icon.svg';

const PDFAttachment = ({ url, filename, link, border = false }) => (
  <div
    className={cx(
      base.flex,
      base.itemsCenter,
      base.justifyStart,
      base.overflowHidden,
      base.w100,
      main.engageProviderAttachmentWrap
    )}
    data-border={`${border}`}
  >
    <div
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        main.engageProviderIconWrap
      )}
    >
      <img
        src={PDFIcon}
        alt=""
        aria-hidden="true"
        className={cx(main.engageProviderIcon)}
      />
    </div>
    <div
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyStart,
        base.w100,
        main.engageProviderAttachment
      )}
    >
      <a
        href={url}
        download={filename}
        target="_blank"
        rel="noopener noreferrer"
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsStart,
          base.justifyCenter,
          main.engageProviderAttachmentLink
        )}
      >
        <span style={{ fontSize: '0.75rem' }}>PDF</span>
        <span style={{ fontSize: '1rem' }}>{link}</span>
      </a>
    </div>
  </div>
);

PDFAttachment.propTypes = {
  url: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  border: PropTypes.arrayOf(PropTypes.object),
};

PDFAttachment.defaultProps = {
  border: false,
};

export default PDFAttachment;
