import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import CleanHTML from '../cleanHTML';

const copyImg = {
  maxWidth: '450px',
  minWidth: '316px',
};

/**
 * Retention page OutreachImpact component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <OutreachImpact />
 */

const OutreachImpact = () => {
  const {
    contentfulMemberExperience: {
      outreachImpactContent: {
        childMarkdownRemark: { html },
      },
      outreachImpactImage: { fluid },
      outreachImpactTitle,
    },
  } = useStaticQuery(graphql`
    query cahpsOutreachImpact {
      contentfulMemberExperience {
        outreachImpactTitle
        outreachImpactContent {
          childMarkdownRemark {
            html
          }
        }
        outreachImpactImage {
          fluid(maxWidth: 600, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `);
  const hasContentUl = html.includes('<ul>');

  return (
    <section
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.mainWrapper,
        main.outreachImpact
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexWrapReverse,
          base.itemsCenter,
          base.justifyBetween,
          base.w100,
          main.dataItem,
          main.mb60
        )}
      >
        <div className={cx(base.ma2, base.w100, main.imgCopyWrap)}>
          <p className={cx(base.mb4, base.mt0, main.subHeading)}>
            {outreachImpactTitle}
          </p>
          <div
            className={(main.statementsContent, main.stCopy)}
            data-shift={`${hasContentUl}`}
          >
            <CleanHTML html={html} />
          </div>
        </div>
        <Img
          fluid={fluid}
          alt=""
          style={copyImg}
          imgStyle={copyImg}
          className={cx(base.mb4)}
        />
      </div>
    </section>
  );
};

export default OutreachImpact;
