import React from 'react';
import PropTypes from 'prop-types';

import Achievers from '../../components/member-experience/archievers';
import CAHPSControl from '../../components/member-experience/cahpsControl';
import ComplementaryEngagement from '../../components/member-experience/complementaryEngagement';
import CTA from '../../components/member-experience/cta';
import EngageProvider from '../../components/member-experience/engageProvider';
import ForecastEngagement from '../../components/member-experience/forecastEngagement';
import Hero from '../../components/member-experience/hero';
import Layout from '../../components/layout';
import Outreach from '../../components/member-experience/outreach';
import OutreachImpact from '../../components/member-experience/outreachImpact';
import ProfilePopulation from '../../components/member-experience/profilePopulation';

const pageDescription =
  'Member satisfaction and loyalty, powered by data. We provide the tools and strategy to retain and satisfy your membership.';

/**
 * MemberExperience page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <MemberExperience location={string} />
 */

const MemberExperience = ({ location }) => (
  <Layout
    pageTitle="CAHPS &amp; Member Experience"
    pageDescription={pageDescription}
    location={location}
  >
    <Hero />
    <Achievers />
    <CAHPSControl />
    <ForecastEngagement />
    <OutreachImpact />
    <ComplementaryEngagement />
    <Outreach />
    <ProfilePopulation />
    <EngageProvider />
    <CTA />
  </Layout>
);

MemberExperience.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default MemberExperience;
